import React, { useContext } from 'react';
import { AppConfigContext } from '../app/context';
import ConfigProvider, { ConfigContext, globalConfig, warnContext } from '../config-provider';
import { getReactRender } from '../config-provider/UnstableContext';
import PurePanel from './PurePanel';
import useMessage, { useInternalMessage } from './useMessage';
import { wrapPromiseFn } from './util';
let message = null;
let act = (callback) => callback();
let taskQueue = [];
let defaultGlobalConfig = {};
function getGlobalContext() {
    const { getContainer, duration, rtl, maxCount, top } = defaultGlobalConfig;
    const mergedContainer = (getContainer === null || getContainer === void 0 ? void 0 : getContainer()) || document.body;
    return { getContainer: () => mergedContainer, duration, rtl, maxCount, top };
}
const GlobalHolder = React.forwardRef((props, ref) => {
    const { messageConfig, sync } = props;
    const { getPrefixCls } = useContext(ConfigContext);
    const prefixCls = defaultGlobalConfig.prefixCls || getPrefixCls('message');
    const appConfig = useContext(AppConfigContext);
    const [api, holder] = useInternalMessage(Object.assign(Object.assign(Object.assign({}, messageConfig), { prefixCls }), appConfig.message));
    React.useImperativeHandle(ref, () => {
        const instance = Object.assign({}, api);
        Object.keys(instance).forEach((method) => {
            instance[method] = (...args) => {
                sync();
                return api[method](...args);
            };
        });
        return {
            instance,
            sync,
        };
    });
    return holder;
});
const GlobalHolderWrapper = React.forwardRef((_, ref) => {
    const [messageConfig, setMessageConfig] = React.useState(getGlobalContext);
    const sync = () => {
        setMessageConfig(getGlobalContext);
    };
    React.useEffect(sync, []);
    const global = globalConfig();
    const rootPrefixCls = global.getRootPrefixCls();
    const rootIconPrefixCls = global.getIconPrefixCls();
    const theme = global.getTheme();
    const dom = React.createElement(GlobalHolder, { ref: ref, sync: sync, messageConfig: messageConfig });
    return (React.createElement(ConfigProvider, { prefixCls: rootPrefixCls, iconPrefixCls: rootIconPrefixCls, theme: theme }, global.holderRender ? global.holderRender(dom) : dom));
});
function flushNotice() {
    if (!message) {
        const holderFragment = document.createDocumentFragment();
        const newMessage = {
            fragment: holderFragment,
        };
        message = newMessage;
        // Delay render to avoid sync issue
        act(() => {
            const reactRender = getReactRender();
            reactRender(React.createElement(GlobalHolderWrapper, { ref: (node) => {
                    const { instance, sync } = node || {};
                    // React 18 test env will throw if call immediately in ref
                    Promise.resolve().then(() => {
                        if (!newMessage.instance && instance) {
                            newMessage.instance = instance;
                            newMessage.sync = sync;
                            flushNotice();
                        }
                    });
                } }), holderFragment);
        });
        return;
    }
    // Notification not ready
    if (!message.instance) {
        return;
    }
    // >>> Execute task
    taskQueue.forEach((task) => {
        const { type, skipped } = task;
        // Only `skipped` when user call notice but cancel it immediately
        // and instance not ready
        if (!skipped) {
            switch (type) {
                case 'open': {
                    act(() => {
                        const closeFn = message.instance.open(Object.assign(Object.assign({}, defaultGlobalConfig), task.config));
                        closeFn === null || closeFn === void 0 ? void 0 : closeFn.then(task.resolve);
                        task.setCloseFn(closeFn);
                    });
                    break;
                }
                case 'destroy':
                    act(() => {
                        message === null || message === void 0 ? void 0 : message.instance.destroy(task.key);
                    });
                    break;
                // Other type open
                default: {
                    act(() => {
                        const closeFn = message.instance[type](...task.args);
                        closeFn === null || closeFn === void 0 ? void 0 : closeFn.then(task.resolve);
                        task.setCloseFn(closeFn);
                    });
                }
            }
        }
    });
    // Clean up
    taskQueue = [];
}
// ==============================================================================
// ==                                  Export                                  ==
// ==============================================================================
function setMessageGlobalConfig(config) {
    defaultGlobalConfig = Object.assign(Object.assign({}, defaultGlobalConfig), config);
    // Trigger sync for it
    act(() => {
        var _a;
        (_a = message === null || message === void 0 ? void 0 : message.sync) === null || _a === void 0 ? void 0 : _a.call(message);
    });
}
function open(config) {
    const result = wrapPromiseFn((resolve) => {
        let closeFn;
        const task = {
            type: 'open',
            config,
            resolve,
            setCloseFn: (fn) => {
                closeFn = fn;
            },
        };
        taskQueue.push(task);
        return () => {
            if (closeFn) {
                act(() => {
                    closeFn();
                });
            }
            else {
                task.skipped = true;
            }
        };
    });
    flushNotice();
    return result;
}
function typeOpen(type, args) {
    const global = globalConfig();
    if (process.env.NODE_ENV !== 'production' && !global.holderRender) {
        warnContext('message');
    }
    const result = wrapPromiseFn((resolve) => {
        let closeFn;
        const task = {
            type,
            args,
            resolve,
            setCloseFn: (fn) => {
                closeFn = fn;
            },
        };
        taskQueue.push(task);
        return () => {
            if (closeFn) {
                act(() => {
                    closeFn();
                });
            }
            else {
                task.skipped = true;
            }
        };
    });
    flushNotice();
    return result;
}
const destroy = (key) => {
    taskQueue.push({
        type: 'destroy',
        key,
    });
    flushNotice();
};
const methods = ['success', 'info', 'warning', 'error', 'loading'];
const baseStaticMethods = {
    open,
    destroy,
    config: setMessageGlobalConfig,
    useMessage,
    _InternalPanelDoNotUseOrYouWillBeFired: PurePanel,
};
const staticMethods = baseStaticMethods;
methods.forEach((type) => {
    staticMethods[type] = (...args) => typeOpen(type, args);
});
// ==============================================================================
// ==                                   Test                                   ==
// ==============================================================================
const noop = () => { };
/** @internal Only Work in test env */
// eslint-disable-next-line import/no-mutable-exports
export let actWrapper = noop;
if (process.env.NODE_ENV === 'test') {
    actWrapper = (wrapper) => {
        act = wrapper;
    };
}
/** @internal Only Work in test env */
// eslint-disable-next-line import/no-mutable-exports
export let actDestroy = noop;
if (process.env.NODE_ENV === 'test') {
    actDestroy = () => {
        message = null;
    };
}
export default staticMethods;
