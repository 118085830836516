var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import { ConfigContext } from '../config-provider';
import useSize from '../config-provider/hooks/useSize';
import useStyle from './style';
export const SpaceCompactItemContext = React.createContext(null);
export const useCompactItemContext = (prefixCls, direction) => {
    const compactItemContext = React.useContext(SpaceCompactItemContext);
    const compactItemClassnames = React.useMemo(() => {
        if (!compactItemContext) {
            return '';
        }
        const { compactDirection, isFirstItem, isLastItem } = compactItemContext;
        const separator = compactDirection === 'vertical' ? '-vertical-' : '-';
        return classNames(`${prefixCls}-compact${separator}item`, {
            [`${prefixCls}-compact${separator}first-item`]: isFirstItem,
            [`${prefixCls}-compact${separator}last-item`]: isLastItem,
            [`${prefixCls}-compact${separator}item-rtl`]: direction === 'rtl',
        });
    }, [prefixCls, direction, compactItemContext]);
    return {
        compactSize: compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.compactSize,
        compactDirection: compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.compactDirection,
        compactItemClassnames,
    };
};
export const NoCompactStyle = (props) => {
    const { children } = props;
    return (React.createElement(SpaceCompactItemContext.Provider, { value: null }, children));
};
const CompactItem = (props) => {
    const { children } = props, others = __rest(props, ["children"]);
    return (React.createElement(SpaceCompactItemContext.Provider, { value: React.useMemo(() => others, [others]) }, children));
};
const Compact = (props) => {
    const { getPrefixCls, direction: directionConfig } = React.useContext(ConfigContext);
    const { size, direction, block, prefixCls: customizePrefixCls, className, rootClassName, children } = props, restProps = __rest(props, ["size", "direction", "block", "prefixCls", "className", "rootClassName", "children"]);
    const mergedSize = useSize((ctx) => size !== null && size !== void 0 ? size : ctx);
    const prefixCls = getPrefixCls('space-compact', customizePrefixCls);
    const [wrapCSSVar, hashId] = useStyle(prefixCls);
    const clx = classNames(prefixCls, hashId, {
        [`${prefixCls}-rtl`]: directionConfig === 'rtl',
        [`${prefixCls}-block`]: block,
        [`${prefixCls}-vertical`]: direction === 'vertical',
    }, className, rootClassName);
    const compactItemContext = React.useContext(SpaceCompactItemContext);
    const childNodes = toArray(children);
    const nodes = React.useMemo(() => childNodes.map((child, i) => {
        const key = (child === null || child === void 0 ? void 0 : child.key) || `${prefixCls}-item-${i}`;
        return (React.createElement(CompactItem, { key: key, compactSize: mergedSize, compactDirection: direction, isFirstItem: i === 0 && (!compactItemContext || (compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.isFirstItem)), isLastItem: i === childNodes.length - 1 && (!compactItemContext || (compactItemContext === null || compactItemContext === void 0 ? void 0 : compactItemContext.isLastItem)) }, child));
    }), [size, childNodes, compactItemContext]);
    // =========================== Render ===========================
    if (childNodes.length === 0) {
        return null;
    }
    return wrapCSSVar(React.createElement("div", Object.assign({ className: clx }, restProps), nodes));
};
export default Compact;
