import { unit } from '@ant-design/cssinjs';
import { AggregationColor } from '../../color-picker/color';
import { isBright } from '../../color-picker/components/ColorPresets';
import { getLineHeight, mergeToken } from '../../theme/internal';
import { PresetColors } from '../../theme/interface';
import getAlphaColor from '../../theme/util/getAlphaColor';
export const prepareToken = (token) => {
    const { paddingInline, onlyIconSize } = token;
    const buttonToken = mergeToken(token, {
        buttonPaddingHorizontal: paddingInline,
        buttonPaddingVertical: 0,
        buttonIconOnlyFontSize: onlyIconSize,
    });
    return buttonToken;
};
export const prepareComponentToken = (token) => {
    var _a, _b, _c, _d, _e, _f;
    const contentFontSize = (_a = token.contentFontSize) !== null && _a !== void 0 ? _a : token.fontSize;
    const contentFontSizeSM = (_b = token.contentFontSizeSM) !== null && _b !== void 0 ? _b : token.fontSize;
    const contentFontSizeLG = (_c = token.contentFontSizeLG) !== null && _c !== void 0 ? _c : token.fontSizeLG;
    const contentLineHeight = (_d = token.contentLineHeight) !== null && _d !== void 0 ? _d : getLineHeight(contentFontSize);
    const contentLineHeightSM = (_e = token.contentLineHeightSM) !== null && _e !== void 0 ? _e : getLineHeight(contentFontSizeSM);
    const contentLineHeightLG = (_f = token.contentLineHeightLG) !== null && _f !== void 0 ? _f : getLineHeight(contentFontSizeLG);
    const solidTextColor = isBright(new AggregationColor(token.colorBgSolid), '#fff')
        ? '#000'
        : '#fff';
    const shadowColorTokens = PresetColors.reduce((prev, colorKey) => (Object.assign(Object.assign({}, prev), { [`${colorKey}ShadowColor`]: `0 ${unit(token.controlOutlineWidth)} 0 ${getAlphaColor(token[`${colorKey}1`], token.colorBgContainer)}` })), {});
    return Object.assign(Object.assign({}, shadowColorTokens), { fontWeight: 400, defaultShadow: `0 ${token.controlOutlineWidth}px 0 ${token.controlTmpOutline}`, primaryShadow: `0 ${token.controlOutlineWidth}px 0 ${token.controlOutline}`, dangerShadow: `0 ${token.controlOutlineWidth}px 0 ${token.colorErrorOutline}`, primaryColor: token.colorTextLightSolid, dangerColor: token.colorTextLightSolid, borderColorDisabled: token.colorBorder, defaultGhostColor: token.colorBgContainer, ghostBg: 'transparent', defaultGhostBorderColor: token.colorBgContainer, paddingInline: token.paddingContentHorizontal - token.lineWidth, paddingInlineLG: token.paddingContentHorizontal - token.lineWidth, paddingInlineSM: 8 - token.lineWidth, onlyIconSize: 'inherit', onlyIconSizeSM: 'inherit', onlyIconSizeLG: 'inherit', groupBorderColor: token.colorPrimaryHover, linkHoverBg: 'transparent', textTextColor: token.colorText, textTextHoverColor: token.colorText, textTextActiveColor: token.colorText, textHoverBg: token.colorFillTertiary, defaultColor: token.colorText, defaultBg: token.colorBgContainer, defaultBorderColor: token.colorBorder, defaultBorderColorDisabled: token.colorBorder, defaultHoverBg: token.colorBgContainer, defaultHoverColor: token.colorPrimaryHover, defaultHoverBorderColor: token.colorPrimaryHover, defaultActiveBg: token.colorBgContainer, defaultActiveColor: token.colorPrimaryActive, defaultActiveBorderColor: token.colorPrimaryActive, solidTextColor,
        contentFontSize,
        contentFontSizeSM,
        contentFontSizeLG,
        contentLineHeight,
        contentLineHeightSM,
        contentLineHeightLG, paddingBlock: Math.max((token.controlHeight - contentFontSize * contentLineHeight) / 2 - token.lineWidth, 0), paddingBlockSM: Math.max((token.controlHeightSM - contentFontSizeSM * contentLineHeightSM) / 2 - token.lineWidth, 0), paddingBlockLG: Math.max((token.controlHeightLG - contentFontSizeLG * contentLineHeightLG) / 2 - token.lineWidth, 0) });
};
