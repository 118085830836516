var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import classNames from 'classnames';
import { matchScreen } from '../_util/responsiveObserver';
import { devUseWarning } from '../_util/warning';
import { useComponentConfig } from '../config-provider/context';
import useSize from '../config-provider/hooks/useSize';
import useBreakpoint from '../grid/hooks/useBreakpoint';
import DEFAULT_COLUMN_MAP from './constant';
import DescriptionsContext from './DescriptionsContext';
import useItems from './hooks/useItems';
import useRow from './hooks/useRow';
import DescriptionsItem from './Item';
import Row from './Row';
import useStyle from './style';
const Descriptions = (props) => {
    const { prefixCls: customizePrefixCls, title, extra, column, colon = true, bordered, layout, children, className, rootClassName, style, size: customizeSize, labelStyle, contentStyle, styles, items, classNames: descriptionsClassNames } = props, restProps = __rest(props, ["prefixCls", "title", "extra", "column", "colon", "bordered", "layout", "children", "className", "rootClassName", "style", "size", "labelStyle", "contentStyle", "styles", "items", "classNames"]);
    const { getPrefixCls, direction, className: contextClassName, style: contextStyle, classNames: contextClassNames, styles: contextStyles, } = useComponentConfig('descriptions');
    const prefixCls = getPrefixCls('descriptions', customizePrefixCls);
    const screens = useBreakpoint();
    // ============================== Warn ==============================
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Descriptions');
        [
            ['labelStyle', 'styles={{ label: {} }}'],
            ['contentStyle', 'styles={{ content: {} }}'],
        ].forEach(([deprecatedName, newName]) => {
            warning.deprecated(!(deprecatedName in props), deprecatedName, newName);
        });
    }
    // Column count
    const mergedColumn = React.useMemo(() => {
        var _a;
        if (typeof column === 'number') {
            return column;
        }
        return ((_a = matchScreen(screens, Object.assign(Object.assign({}, DEFAULT_COLUMN_MAP), column))) !== null && _a !== void 0 ? _a : 3);
    }, [screens, column]);
    // Items with responsive
    const mergedItems = useItems(screens, items, children);
    const mergedSize = useSize(customizeSize);
    const rows = useRow(mergedColumn, mergedItems);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    // ======================== Render ========================
    const contextValue = React.useMemo(() => ({
        labelStyle,
        contentStyle,
        styles: {
            content: Object.assign(Object.assign({}, contextStyles.content), styles === null || styles === void 0 ? void 0 : styles.content),
            label: Object.assign(Object.assign({}, contextStyles.label), styles === null || styles === void 0 ? void 0 : styles.label),
        },
        classNames: {
            label: classNames(contextClassNames.label, descriptionsClassNames === null || descriptionsClassNames === void 0 ? void 0 : descriptionsClassNames.label),
            content: classNames(contextClassNames.content, descriptionsClassNames === null || descriptionsClassNames === void 0 ? void 0 : descriptionsClassNames.content),
        },
    }), [labelStyle, contentStyle, styles, descriptionsClassNames, contextClassNames, contextStyles]);
    return wrapCSSVar(React.createElement(DescriptionsContext.Provider, { value: contextValue },
        React.createElement("div", Object.assign({ className: classNames(prefixCls, contextClassName, contextClassNames.root, descriptionsClassNames === null || descriptionsClassNames === void 0 ? void 0 : descriptionsClassNames.root, {
                [`${prefixCls}-${mergedSize}`]: mergedSize && mergedSize !== 'default',
                [`${prefixCls}-bordered`]: !!bordered,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className, rootClassName, hashId, cssVarCls), style: Object.assign(Object.assign(Object.assign(Object.assign({}, contextStyle), contextStyles.root), styles === null || styles === void 0 ? void 0 : styles.root), style) }, restProps),
            (title || extra) && (React.createElement("div", { className: classNames(`${prefixCls}-header`, contextClassNames.header, descriptionsClassNames === null || descriptionsClassNames === void 0 ? void 0 : descriptionsClassNames.header), style: Object.assign(Object.assign({}, contextStyles.header), styles === null || styles === void 0 ? void 0 : styles.header) },
                title && (React.createElement("div", { className: classNames(`${prefixCls}-title`, contextClassNames.title, descriptionsClassNames === null || descriptionsClassNames === void 0 ? void 0 : descriptionsClassNames.title), style: Object.assign(Object.assign({}, contextStyles.title), styles === null || styles === void 0 ? void 0 : styles.title) }, title)),
                extra && (React.createElement("div", { className: classNames(`${prefixCls}-extra`, contextClassNames.extra, descriptionsClassNames === null || descriptionsClassNames === void 0 ? void 0 : descriptionsClassNames.extra), style: Object.assign(Object.assign({}, contextStyles.extra), styles === null || styles === void 0 ? void 0 : styles.extra) }, extra)))),
            React.createElement("div", { className: `${prefixCls}-view` },
                React.createElement("table", null,
                    React.createElement("tbody", null, rows.map((row, index) => (React.createElement(Row, { key: index, index: index, colon: colon, prefixCls: prefixCls, vertical: layout === 'vertical', bordered: bordered, row: row })))))))));
};
if (process.env.NODE_ENV !== 'production') {
    Descriptions.displayName = 'Descriptions';
}
export { DescriptionsContext };
Descriptions.Item = DescriptionsItem;
export default Descriptions;
