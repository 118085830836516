var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import KeyCode from 'rc-util/lib/KeyCode';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { getTransitionName } from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import Tooltip from '../tooltip';
import PurePanel, { Overlay } from './PurePanel';
import { useComponentConfig } from '../config-provider/context';
// CSSINJS
import useStyle from './style';
const InternalPopover = React.forwardRef((props, ref) => {
    var _a, _b;
    const { prefixCls: customizePrefixCls, title, content, overlayClassName, placement = 'top', trigger = 'hover', children, mouseEnterDelay = 0.1, mouseLeaveDelay = 0.1, onOpenChange, overlayStyle = {}, styles, classNames: popoverClassNames } = props, otherProps = __rest(props, ["prefixCls", "title", "content", "overlayClassName", "placement", "trigger", "children", "mouseEnterDelay", "mouseLeaveDelay", "onOpenChange", "overlayStyle", "styles", "classNames"]);
    const { getPrefixCls, className: contextClassName, style: contextStyle, classNames: contextClassNames, styles: contextStyles, } = useComponentConfig('popover');
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const rootPrefixCls = getPrefixCls();
    const rootClassNames = classNames(overlayClassName, hashId, cssVarCls, contextClassName, contextClassNames.root, popoverClassNames === null || popoverClassNames === void 0 ? void 0 : popoverClassNames.root);
    const bodyClassNames = classNames(contextClassNames.body, popoverClassNames === null || popoverClassNames === void 0 ? void 0 : popoverClassNames.body);
    const [open, setOpen] = useMergedState(false, {
        value: (_a = props.open) !== null && _a !== void 0 ? _a : props.visible,
        defaultValue: (_b = props.defaultOpen) !== null && _b !== void 0 ? _b : props.defaultVisible,
    });
    const settingOpen = (value, e) => {
        setOpen(value, true);
        onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(value, e);
    };
    const onKeyDown = (e) => {
        if (e.keyCode === KeyCode.ESC) {
            settingOpen(false, e);
        }
    };
    const onInternalOpenChange = (value) => {
        settingOpen(value);
    };
    const titleNode = getRenderPropValue(title);
    const contentNode = getRenderPropValue(content);
    return wrapCSSVar(React.createElement(Tooltip, Object.assign({ placement: placement, trigger: trigger, mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay }, otherProps, { prefixCls: prefixCls, classNames: { root: rootClassNames, body: bodyClassNames }, styles: {
            root: Object.assign(Object.assign(Object.assign(Object.assign({}, contextStyles.root), contextStyle), overlayStyle), styles === null || styles === void 0 ? void 0 : styles.root),
            body: Object.assign(Object.assign({}, contextStyles.body), styles === null || styles === void 0 ? void 0 : styles.body),
        }, ref: ref, open: open, onOpenChange: onInternalOpenChange, overlay: titleNode || contentNode ? (React.createElement(Overlay, { prefixCls: prefixCls, title: titleNode, content: contentNode })) : null, transitionName: getTransitionName(rootPrefixCls, 'zoom-big', otherProps.transitionName), "data-popover-inject": true }), cloneElement(children, {
        onKeyDown: (e) => {
            var _a, _b;
            if (React.isValidElement(children)) {
                (_b = children === null || children === void 0 ? void 0 : (_a = children.props).onKeyDown) === null || _b === void 0 ? void 0 : _b.call(_a, e);
            }
            onKeyDown(e);
        },
    })));
});
const Popover = InternalPopover;
Popover._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
if (process.env.NODE_ENV !== 'production') {
    Popover.displayName = 'Popover';
}
export default Popover;
