var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcDrawer from 'rc-drawer';
import ContextIsolator from '../_util/ContextIsolator';
import { useZIndex } from '../_util/hooks/useZIndex';
import { getTransitionName } from '../_util/motion';
import { devUseWarning } from '../_util/warning';
import zIndexContext from '../_util/zindexContext';
import { ConfigContext } from '../config-provider';
import { useComponentConfig } from '../config-provider/context';
import { usePanelRef } from '../watermark/context';
import DrawerPanel from './DrawerPanel';
import useStyle from './style';
const _SizeTypes = ['default', 'large'];
const defaultPushState = { distance: 180 };
const Drawer = (props) => {
    var _a;
    const { rootClassName, width, height, size = 'default', mask = true, push = defaultPushState, open, afterOpenChange, onClose, prefixCls: customizePrefixCls, getContainer: customizeGetContainer, style, className, 
    // Deprecated
    visible, afterVisibleChange, maskStyle, drawerStyle, contentWrapperStyle } = props, rest = __rest(props, ["rootClassName", "width", "height", "size", "mask", "push", "open", "afterOpenChange", "onClose", "prefixCls", "getContainer", "style", "className", "visible", "afterVisibleChange", "maskStyle", "drawerStyle", "contentWrapperStyle"]);
    const { getPopupContainer, getPrefixCls, direction, className: contextClassName, style: contextStyle, classNames: contextClassNames, styles: contextStyles, } = useComponentConfig('drawer');
    const prefixCls = getPrefixCls('drawer', customizePrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const getContainer = 
    // 有可能为 false，所以不能直接判断
    customizeGetContainer === undefined && getPopupContainer
        ? () => getPopupContainer(document.body)
        : customizeGetContainer;
    const drawerClassName = classNames({
        'no-mask': !mask,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, rootClassName, hashId, cssVarCls);
    // ========================== Warning ===========================
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Drawer');
        [
            ['visible', 'open'],
            ['afterVisibleChange', 'afterOpenChange'],
            ['headerStyle', 'styles.header'],
            ['bodyStyle', 'styles.body'],
            ['footerStyle', 'styles.footer'],
            ['contentWrapperStyle', 'styles.wrapper'],
            ['maskStyle', 'styles.mask'],
            ['drawerStyle', 'styles.content'],
        ].forEach(([deprecatedName, newName]) => {
            warning.deprecated(!(deprecatedName in props), deprecatedName, newName);
        });
        if (getContainer !== undefined && ((_a = props.style) === null || _a === void 0 ? void 0 : _a.position) === 'absolute') {
            warning(false, 'breaking', '`style` is replaced by `rootStyle` in v5. Please check that `position: absolute` is necessary.');
        }
    }
    // ============================ Size ============================
    const mergedWidth = React.useMemo(() => width !== null && width !== void 0 ? width : (size === 'large' ? 736 : 378), [width, size]);
    const mergedHeight = React.useMemo(() => height !== null && height !== void 0 ? height : (size === 'large' ? 736 : 378), [height, size]);
    // =========================== Motion ===========================
    const maskMotion = {
        motionName: getTransitionName(prefixCls, 'mask-motion'),
        motionAppear: true,
        motionEnter: true,
        motionLeave: true,
        motionDeadline: 500,
    };
    const panelMotion = (motionPlacement) => ({
        motionName: getTransitionName(prefixCls, `panel-motion-${motionPlacement}`),
        motionAppear: true,
        motionEnter: true,
        motionLeave: true,
        motionDeadline: 500,
    });
    // ============================ Refs ============================
    // Select `ant-drawer-content` by `panelRef`
    const panelRef = usePanelRef();
    // ============================ zIndex ============================
    const [zIndex, contextZIndex] = useZIndex('Drawer', rest.zIndex);
    // =========================== Render ===========================
    const { classNames: propClassNames = {}, styles: propStyles = {} } = rest;
    return wrapCSSVar(React.createElement(ContextIsolator, { form: true, space: true },
        React.createElement(zIndexContext.Provider, { value: contextZIndex },
            React.createElement(RcDrawer, Object.assign({ prefixCls: prefixCls, onClose: onClose, maskMotion: maskMotion, motion: panelMotion }, rest, { classNames: {
                    mask: classNames(propClassNames.mask, contextClassNames.mask),
                    content: classNames(propClassNames.content, contextClassNames.content),
                    wrapper: classNames(propClassNames.wrapper, contextClassNames.wrapper),
                }, styles: {
                    mask: Object.assign(Object.assign(Object.assign({}, propStyles.mask), maskStyle), contextStyles.mask),
                    content: Object.assign(Object.assign(Object.assign({}, propStyles.content), drawerStyle), contextStyles.content),
                    wrapper: Object.assign(Object.assign(Object.assign({}, propStyles.wrapper), contentWrapperStyle), contextStyles.wrapper),
                }, open: open !== null && open !== void 0 ? open : visible, mask: mask, push: push, width: mergedWidth, height: mergedHeight, style: Object.assign(Object.assign({}, contextStyle), style), className: classNames(contextClassName, className), rootClassName: drawerClassName, getContainer: getContainer, afterOpenChange: afterOpenChange !== null && afterOpenChange !== void 0 ? afterOpenChange : afterVisibleChange, panelRef: panelRef, zIndex: zIndex }),
                React.createElement(DrawerPanel, Object.assign({ prefixCls: prefixCls }, rest, { onClose: onClose }))))));
};
/** @private Internal Component. Do not use in your production. */
const PurePanel = (props) => {
    const { prefixCls: customizePrefixCls, style, className, placement = 'right' } = props, restProps = __rest(props, ["prefixCls", "style", "className", "placement"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('drawer', customizePrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const cls = classNames(prefixCls, `${prefixCls}-pure`, `${prefixCls}-${placement}`, hashId, cssVarCls, className);
    return wrapCSSVar(React.createElement("div", { className: cls, style: style },
        React.createElement(DrawerPanel, Object.assign({ prefixCls: prefixCls }, restProps))));
};
Drawer._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
if (process.env.NODE_ENV !== 'production') {
    Drawer.displayName = 'Drawer';
}
export default Drawer;
