import * as React from 'react';
import classNames from 'classnames';
import { useComponentConfig } from '../config-provider/context';
import SkeletonAvatar from './Avatar';
import SkeletonButton from './Button';
import Element from './Element';
import SkeletonImage from './Image';
import SkeletonInput from './Input';
import SkeletonNode from './Node';
import Paragraph from './Paragraph';
import useStyle from './style';
import Title from './Title';
function getComponentProps(prop) {
    if (prop && typeof prop === 'object') {
        return prop;
    }
    return {};
}
function getAvatarBasicProps(hasTitle, hasParagraph) {
    if (hasTitle && !hasParagraph) {
        // Square avatar
        return { size: 'large', shape: 'square' };
    }
    return { size: 'large', shape: 'circle' };
}
function getTitleBasicProps(hasAvatar, hasParagraph) {
    if (!hasAvatar && hasParagraph) {
        return { width: '38%' };
    }
    if (hasAvatar && hasParagraph) {
        return { width: '50%' };
    }
    return {};
}
function getParagraphBasicProps(hasAvatar, hasTitle) {
    const basicProps = {};
    // Width
    if (!hasAvatar || !hasTitle) {
        basicProps.width = '61%';
    }
    // Rows
    if (!hasAvatar && hasTitle) {
        basicProps.rows = 3;
    }
    else {
        basicProps.rows = 2;
    }
    return basicProps;
}
const Skeleton = (props) => {
    const { prefixCls: customizePrefixCls, loading, className, rootClassName, style, children, avatar = false, title = true, paragraph = true, active, round, } = props;
    const { getPrefixCls, direction, className: contextClassName, style: contextStyle, } = useComponentConfig('skeleton');
    const prefixCls = getPrefixCls('skeleton', customizePrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    if (loading || !('loading' in props)) {
        const hasAvatar = !!avatar;
        const hasTitle = !!title;
        const hasParagraph = !!paragraph;
        // Avatar
        let avatarNode;
        if (hasAvatar) {
            const avatarProps = Object.assign(Object.assign({ prefixCls: `${prefixCls}-avatar` }, getAvatarBasicProps(hasTitle, hasParagraph)), getComponentProps(avatar));
            // We direct use SkeletonElement as avatar in skeleton internal.
            avatarNode = (React.createElement("div", { className: `${prefixCls}-header` },
                React.createElement(Element, Object.assign({}, avatarProps))));
        }
        let contentNode;
        if (hasTitle || hasParagraph) {
            // Title
            let $title;
            if (hasTitle) {
                const titleProps = Object.assign(Object.assign({ prefixCls: `${prefixCls}-title` }, getTitleBasicProps(hasAvatar, hasParagraph)), getComponentProps(title));
                $title = React.createElement(Title, Object.assign({}, titleProps));
            }
            // Paragraph
            let paragraphNode;
            if (hasParagraph) {
                const paragraphProps = Object.assign(Object.assign({ prefixCls: `${prefixCls}-paragraph` }, getParagraphBasicProps(hasAvatar, hasTitle)), getComponentProps(paragraph));
                paragraphNode = React.createElement(Paragraph, Object.assign({}, paragraphProps));
            }
            contentNode = (React.createElement("div", { className: `${prefixCls}-content` },
                $title,
                paragraphNode));
        }
        const cls = classNames(prefixCls, {
            [`${prefixCls}-with-avatar`]: hasAvatar,
            [`${prefixCls}-active`]: active,
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-round`]: round,
        }, contextClassName, className, rootClassName, hashId, cssVarCls);
        return wrapCSSVar(React.createElement("div", { className: cls, style: Object.assign(Object.assign({}, contextStyle), style) },
            avatarNode,
            contentNode));
    }
    return children !== null && children !== void 0 ? children : null;
};
Skeleton.Button = SkeletonButton;
Skeleton.Avatar = SkeletonAvatar;
Skeleton.Input = SkeletonInput;
Skeleton.Image = SkeletonImage;
Skeleton.Node = SkeletonNode;
if (process.env.NODE_ENV !== 'production') {
    Skeleton.displayName = 'Skeleton';
}
export default Skeleton;
