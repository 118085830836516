var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useForm as useRcForm } from 'rc-field-form';
import { getDOM } from 'rc-util/lib/Dom/findDOMNode';
import scrollIntoView from 'scroll-into-view-if-needed';
import { getFieldId, toArray } from '../util';
function toNamePathStr(name) {
    const namePath = toArray(name);
    return namePath.join('_');
}
function getFieldDOMNode(name, wrapForm) {
    const field = wrapForm.getFieldInstance(name);
    const fieldDom = getDOM(field);
    if (fieldDom) {
        return fieldDom;
    }
    const fieldId = getFieldId(toArray(name), wrapForm.__INTERNAL__.name);
    if (fieldId) {
        return document.getElementById(fieldId);
    }
}
export default function useForm(form) {
    const [rcForm] = useRcForm();
    const itemsRef = React.useRef({});
    const wrapForm = React.useMemo(() => form !== null && form !== void 0 ? form : Object.assign(Object.assign({}, rcForm), { __INTERNAL__: {
            itemRef: (name) => (node) => {
                const namePathStr = toNamePathStr(name);
                if (node) {
                    itemsRef.current[namePathStr] = node;
                }
                else {
                    delete itemsRef.current[namePathStr];
                }
            },
        }, scrollToField: (name, options = {}) => {
            const { focus } = options, restOpt = __rest(options, ["focus"]);
            const node = getFieldDOMNode(name, wrapForm);
            if (node) {
                scrollIntoView(node, Object.assign({ scrollMode: 'if-needed', block: 'nearest' }, restOpt));
                // Focus if scroll success
                if (focus) {
                    wrapForm.focusField(name);
                }
            }
        }, focusField: (name) => {
            var _a, _b;
            const itemRef = wrapForm.getFieldInstance(name);
            if (typeof (itemRef === null || itemRef === void 0 ? void 0 : itemRef.focus) === 'function') {
                itemRef.focus();
            }
            else {
                (_b = (_a = getFieldDOMNode(name, wrapForm)) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
            }
        }, getFieldInstance: (name) => {
            const namePathStr = toNamePathStr(name);
            return itemsRef.current[namePathStr];
        } }), [form, rcForm]);
    return [wrapForm];
}
