import * as React from 'react';
import useEvent from 'rc-util/lib/hooks/useEvent';
import raf from 'rc-util/lib/raf';
import { ConfigContext } from '../../config-provider';
import useToken from '../../theme/useToken';
import { TARGET_CLS } from './interface';
import showWaveEffect from './WaveEffect';
const useWave = (nodeRef, className, component) => {
    const { wave } = React.useContext(ConfigContext);
    const [, token, hashId] = useToken();
    const showWave = useEvent((event) => {
        const node = nodeRef.current;
        if ((wave === null || wave === void 0 ? void 0 : wave.disabled) || !node) {
            return;
        }
        const targetNode = node.querySelector(`.${TARGET_CLS}`) || node;
        const { showEffect } = wave || {};
        // Customize wave effect
        (showEffect || showWaveEffect)(targetNode, {
            className,
            token,
            component,
            event,
            hashId,
        });
    });
    const rafId = React.useRef(null);
    // Merge trigger event into one for each frame
    const showDebounceWave = (event) => {
        raf.cancel(rafId.current);
        rafId.current = raf(() => {
            showWave(event);
        });
    };
    return showDebounceWave;
};
export default useWave;
