var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import { ConfigContext } from '../config-provider';
import { useComponentConfig } from '../config-provider/context';
import { LayoutContext } from './context';
import useHasSider from './hooks/useHasSider';
import useStyle from './style';
function generator({ suffixCls, tagName, displayName }) {
    return (BasicComponent) => {
        const Adapter = React.forwardRef((props, ref) => (React.createElement(BasicComponent, Object.assign({ ref: ref, suffixCls: suffixCls, tagName: tagName }, props))));
        if (process.env.NODE_ENV !== 'production') {
            Adapter.displayName = displayName;
        }
        return Adapter;
    };
}
const Basic = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, suffixCls, className, tagName: TagName } = props, others = __rest(props, ["prefixCls", "suffixCls", "className", "tagName"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('layout', customizePrefixCls);
    const [wrapSSR, hashId, cssVarCls] = useStyle(prefixCls);
    const prefixWithSuffixCls = suffixCls ? `${prefixCls}-${suffixCls}` : prefixCls;
    return wrapSSR(React.createElement(TagName, Object.assign({ className: classNames(customizePrefixCls || prefixWithSuffixCls, className, hashId, cssVarCls), ref: ref }, others)));
});
const BasicLayout = React.forwardRef((props, ref) => {
    const { direction } = React.useContext(ConfigContext);
    const [siders, setSiders] = React.useState([]);
    const { prefixCls: customizePrefixCls, className, rootClassName, children, hasSider, tagName: Tag, style } = props, others = __rest(props, ["prefixCls", "className", "rootClassName", "children", "hasSider", "tagName", "style"]);
    const passedProps = omit(others, ['suffixCls']);
    const { getPrefixCls, className: contextClassName, style: contextStyle, } = useComponentConfig('layout');
    const prefixCls = getPrefixCls('layout', customizePrefixCls);
    const mergedHasSider = useHasSider(siders, children, hasSider);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const classString = classNames(prefixCls, {
        [`${prefixCls}-has-sider`]: mergedHasSider,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, contextClassName, className, rootClassName, hashId, cssVarCls);
    const contextValue = React.useMemo(() => ({
        siderHook: {
            addSider: (id) => {
                setSiders((prev) => [...prev, id]);
            },
            removeSider: (id) => {
                setSiders((prev) => prev.filter((currentId) => currentId !== id));
            },
        },
    }), []);
    return wrapCSSVar(React.createElement(LayoutContext.Provider, { value: contextValue },
        React.createElement(Tag, Object.assign({ ref: ref, className: classString, style: Object.assign(Object.assign({}, contextStyle), style) }, passedProps), children)));
});
const Layout = generator({
    tagName: 'div',
    displayName: 'Layout',
})(BasicLayout);
const Header = generator({
    suffixCls: 'header',
    tagName: 'header',
    displayName: 'Header',
})(Basic);
const Footer = generator({
    suffixCls: 'footer',
    tagName: 'footer',
    displayName: 'Footer',
})(Basic);
const Content = generator({
    suffixCls: 'content',
    tagName: 'main',
    displayName: 'Content',
})(Basic);
export { Content, Footer, Header };
export default Layout;
