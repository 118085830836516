import { responsiveArray } from '../../_util/responsiveObserver';
export default function useGutter(gutter, screens) {
    const results = [undefined, undefined];
    const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, undefined];
    // By default use as `xs`
    const mergedScreens = screens || {
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true,
        xxl: true,
    };
    normalizedGutter.forEach((g, index) => {
        if (typeof g === 'object' && g !== null) {
            for (let i = 0; i < responsiveArray.length; i++) {
                const breakpoint = responsiveArray[i];
                if (mergedScreens[breakpoint] && g[breakpoint] !== undefined) {
                    results[index] = g[breakpoint];
                    break;
                }
            }
        }
        else {
            results[index] = g;
        }
    });
    return results;
}
